import { usePathname } from 'next/navigation';
import { useTranslation } from 'next-i18next';

const useActiveLink = (): ((path: string) => boolean) => {
  const { i18n } = useTranslation();
  const pathname = usePathname();

  return (path: string) => {
    return pathname === path || `/${i18n.language}${pathname}` === path;
  };
};

export default useActiveLink;

import { gql } from '@apollo/client';

export const COUNT_OF_PROFILES = gql`
  query CountOfProfiles {
    countOfProfiles
  }
`;

export const CONTACTS_FOR_DOMAIN = gql`
  query ContactsForDomain {
    contactsForDomain {
      customerCarePhone
      customerCareEmail
    }
  }
`;

export const REQUEST_CONTEXT_INFORMATION = gql`
  query RequestContextInformation($ipAddress: String!, $forwardedFor: String) {
    requestContextInformation(ipAddress: $ipAddress, forwardedFor: $forwardedFor) {
      isInternalRequest
      isExcludedFromGoogleAnalytics
      isDevEnvironment
    }
  }
`;

import { useQuery } from '@apollo/client';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import React, { ReactNode, useContext } from 'react';
import { useCookies } from 'react-cookie';
import FlashMessages from '@components/utilities/FlashMessages/FlashMessages';
import { getSiteName } from '@libs/getSiteName';
import { nonEmptyStringOrNull } from '@libs/stringUtils';
import {
  EnabledFeaturesQuery,
  UserDataForIntercomQuery,
  UserIdentificationHashQuery,
} from 'graphql/generated/types/graphqlTypes';
import { CookiesConstants } from '../../generated/phpConstants';
import { ENABLED_FEATURES } from '../../graphql/queries/featureToggle';
import { USER_DATA_FOR_INTERCOM, USER_IDENTIFICATION_HASH } from '../../graphql/queries/user';
import { RequestContext } from '../../helpers/requestContext';

interface Props {
  children?: ReactNode;
}

const BaseLayout = ({ children }: Props) => {
  const { i18n, t } = useTranslation(['messages']);
  const [cookies] = useCookies();

  const { data: enabledFeaturesData } = useQuery<EnabledFeaturesQuery>(ENABLED_FEATURES, {
    fetchPolicy: 'cache-only',
  });
  const { data: loggedInUserHash } = useQuery<UserIdentificationHashQuery>(USER_IDENTIFICATION_HASH);

  const { data: userDataForIntercom } = useQuery<UserDataForIntercomQuery>(USER_DATA_FOR_INTERCOM, {});

  const requestContextData = useContext(RequestContext);
  const userMatchingPreferencesToken = nonEmptyStringOrNull(cookies[CookiesConstants.USER_MATCHING_PREFERENCES_HASH]);
  const siteName = getSiteName();

  return (
    <>
      <Head key="atmoskop-variables">
        <script
          dangerouslySetInnerHTML={{
            __html: `
          window.atmoskop = window.atmoskop || {};
          atmoskop.isDevEnvironment = ${requestContextData?.isDevEnvironment};
          atmoskop.isInternalRequest = ${requestContextData?.isInternalRequest};
          atmoskop.isExcludedFromAnalytics = ${requestContextData?.isExcludedFromGoogleAnalytics};
          atmoskop.isUserLoggedIn = ${!!loggedInUserHash?.userIdentificationHash};
          atmoskop.userDataForIntercom = ${JSON.stringify(userDataForIntercom?.userDataForIntercom)};
          atmoskop.userId = ${
            loggedInUserHash?.userIdentificationHash === ''
              ? undefined
              : `"${loggedInUserHash?.userIdentificationHash}"`
          };
          atmoskop.featureIntercomEnabled = ${enabledFeaturesData?.enabledFeatures.includes('feature_intercom')};
          atmoskop.locale = "${i18n.language}";
          atmoskop.userMatchingPreferencesToken = ${
            userMatchingPreferencesToken !== null ? `"${userMatchingPreferencesToken}"` : undefined
          };
          `,
          }}
        />
      </Head>
      <Head>
        <title>{t('META_TITLE', { siteName })}</title>
        <meta name="title" content={t('META_TITLE', { siteName })} key="title" />
        <meta name="description" content={t('META_DESCRIPTION_SHORTENED')} key="description" />
        <meta property="og:title" content={t('META_TITLE', { siteName })} key="og-title" />
        <meta property="og:description" content={t('META_DESCRIPTION_SHORTENED')} key="og-description" />
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
      </Head>
      <FlashMessages />
      {children}
    </>
  );
};

export default BaseLayout;

import { useQuery } from '@apollo/client';
import {
  ProfileByB2bReportAccessHashQuery,
  ProfileByB2bReportAccessHashQueryVariables,
} from '../../../graphql/generated/types/graphqlTypes';
import { PROFILE_BY_B2B_REPORT_ACCESS_HASH } from '../../../graphql/queries/b2bAdmin';

export const useB2bProfile = (b2bReportAccessHash: string) => {
  const { data } = useQuery<ProfileByB2bReportAccessHashQuery, ProfileByB2bReportAccessHashQueryVariables>(
    PROFILE_BY_B2B_REPORT_ACCESS_HASH,
    {
      variables: {
        b2bReportAccessHash,
      },
    },
  );

  return data?.profileByB2bReportAccessHash;
};
